<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16 3C8.82029 3 3 8.85586 3 16.0794C3 22.6077 7.75389 28.0188 13.9688 29V19.8602H10.668V16.0794H13.9688V13.1979C13.9688 9.91985 15.9096 8.10916 18.879 8.10916C20.3009 8.10916 21.7891 8.36462 21.7891 8.36462V11.5834H20.1498C18.535 11.5834 18.0312 12.5917 18.0312 13.6271V16.0794H21.6367L21.0604 19.8602H18.0312V29C24.2461 28.0188 29 22.6077 29 16.0794C29 8.85586 23.1797 3 16 3Z"
		></path>
	</svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "IconFacebook",
});
</script>
